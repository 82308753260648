<template>
  <el-card>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="tableName" size="small" @change="tabChange">
        <el-radio-button label="tab1">有我司货号</el-radio-button>
        <el-radio-button label="tab2">无我司货号</el-radio-button>
      </el-radio-group>
      提交时间:
      <el-date-picker
        v-model="dateRange"
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        end-placeholder="提交结束日期"
        range-separator="至"
        size="small"
        start-placeholder="提交开始日期"
        type="daterange"
        value-format="timestamp"
      />
      <el-button size="small" type="primary" @click="toCompleted()">完成</el-button>
      <el-button size="small" type="primary" @click="search()">查询</el-button>
      <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
    </div>
    <search-table
      v-show="tableName === 'tab1'"
      ref="multiTable"
      :data="tableData"
      :tableRowKey="`index`"
      :columns="tableProperties"
      v-loading="loading"
      :need-fixed-height="true"
      @row-dblclick="dbClickJp"
      @getTableData="getRequPushList"
      :needPagination="false"
      @selection-change="handleSelectionChange"
    >
    </search-table>
    <search-table
      v-show="tableName === 'tab2'"
      ref="multiTable2"
      v-loading="loading"
      :columns="tableProperties2"
      :data="tableData2"
      :need-fixed-height="true"
      :needPagination="false"
      :tableRowKey="`index2`"
      @getTableData="getRequPushList2"
      @row-dblclick="dbClickJp"
      @selection-change="handleSelectionChange2"
    >
    </search-table>
  </el-card>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { requAPI } from '@api/modules/requ';
import bus from '@/components/common/bus';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';
import { setTime } from '@api/public';

export default {
  name: 'requPush9002',
  components: { SearchTable },
  data() {
    return {
      tableData: [],
      tableData2: [],
      loading: true,
      loading2: true,
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          prop: 'sub_time',
          itemType: 'input',
          label: '提交时间',
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, false)
        },
        { prop: 'requ_no', itemType: 'input', label: '委托打样单号', input: true, sortable: false },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称', input: true, sortable: false },
        { prop: 'prod_no', itemType: 'input', label: '我司货号', input: true, sortable: false },
        { prop: 'prod_name', itemType: 'input', label: '样品名称', input: false, sortable: false },
        { prop: 'requ_smpl_num', itemType: 'input', label: '样品数量', input: false, sortable: false },
        { prop: 'requ_stff_name', itemType: 'input', label: '委托人', input: true, sortable: false, labelWidth: 130 },
        {
          prop: 'requ_dept_id',
          itemType: 'select',
          label: '提交审核部门',
          input: true,
          sortable: false,
          options: [
            { value: 6, label: '采购一部' },
            { value: 7, label: '采购二部' },
            { value: 20, label: '辅料部' }
          ],
          formatter: val => {
            if (val === 6) {
              return '采购一部';
            } else if (val === 7) {
              return '采购二部';
            } else {
              return '辅料部';
            }
          }
        },
        { prop: 'requ_smpl_taker_name', itemType: 'input', label: '打样接收人', input: true, sortable: false },
        { prop: 'requ_smpl_quoter_name', itemType: 'input', label: '报价接收人', input: true, sortable: false },
        { prop: 'requ_smpl_pricer_name', itemType: 'input', label: '核价接收人', input: true, sortable: false },
        {
          prop: 'requ_smpl_pedate',
          itemType: 'input',
          label: '要求交样日期',
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'requ_smpl_qudate',
          itemType: 'input',
          label: '要求核价日期',
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'push_flag',
          itemType: 'input',
          label: '提醒类型',
          input: false,
          sortable: false,
          formatter: val => {
            if (val === 0) {
              return '打样';
            } else if (val === 1) {
              return '报价';
            } else {
              return '核价';
            }
          }
        }
      ],
      tableProperties2: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          prop: 'sub_time',
          itemType: 'input',
          label: '提交时间',
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, false)
        },
        { prop: 'requ_no', itemType: 'input', label: '委托打样单号', input: true, sortable: false },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称', input: true, sortable: false },
        { prop: 'prod_cust_no', itemType: 'input', label: '客户货号', input: true, sortable: false },
        { prop: 'prod_name', itemType: 'input', label: '样品名称', input: false, sortable: false },
        { prop: 'requ_smpl_num', itemType: 'input', label: '样品数量', input: false, sortable: false },
        {
          prop: 'requ_smpl_quot',
          itemType: 'select',
          options: [
            { value: 1, label: '打样并报价' },
            { value: 2, label: '试样报价' },
            { value: 3, label: '仅打样' },
            { value: 4, label: '估价' },
            { value: 5, label: '返工' },
            { value: 6, label: '仅报价' },
            { value: 7, label: '其他' }
          ],
          label: '委托类型',
          input: true,
          sortable: false,
          formatter: val => {
            switch (val) {
              case 1:
                return '打样并报价';
              case 2:
                return '试样报价';
              case 3:
                return '仅打样';
              case 4:
                return '估价';
              case 5:
                return '返工';
              case 6:
                return '仅报价';
              case 7:
                return '其他';
              default:
                return '';
            }
          }
        },
        {
          prop: 'requ_smpl_pedate',
          itemType: 'date',
          label: '要求交样日期',
          input: true,
          sortable: true,
          valueFormat: 'timestamp',
          formatter: val => getDateNoTime(val, true)
        },
        { prop: 'requ_stff_name', itemType: 'input', label: '委托人', input: true, sortable: false, labelWidth: 130 },
        {
          prop: 'requ_dept_id',
          itemType: 'select',
          label: '提交审核部门',
          input: true,
          sortable: false,
          options: [
            { value: 6, label: '采购一部' },
            { value: 7, label: '采购二部' },
            { value: 20, label: '辅料部' }
          ],
          formatter: val => {
            if (val === 6) {
              return '采购一部';
            } else if (val === 7) {
              return '采购二部';
            } else {
              return '辅料部';
            }
          }
        },
        { prop: 'requ_smpl_taker_name', itemType: 'input', label: '打样接收人', input: true, sortable: false },
        { prop: 'requ_smpl_quoter_name', itemType: 'input', label: '报价接收人', input: true, sortable: false },
        { prop: 'requ_smpl_pricer_name', itemType: 'input', label: '核价接收人', input: true, sortable: false },
        {
          prop: 'push_flag',
          itemType: 'input',
          label: '提醒类型',
          input: false,
          sortable: false,
          formatter: val => {
            if (val === 0) {
              return '打样';
            } else if (val === 1) {
              return '报价';
            } else {
              return '核价';
            }
          }
        }
      ],
      selections: [],
      selections2: [],
      dateRange: [],
      tableName: 'tab1'
    };
  },
  mounted() {
    this.initData();
    bus.$on('getRequPush9002Init', msg => {
      this.initData();
    });
    setTimeout(() => {
      this.$refs.multiTable2.tableMaxHeight = this.$refs.multiTable.tableMaxHeight;
    });
  },
  methods: {
    initData() {
      this.getRequPushList();
      this.getRequPushList2();
    },
    search() {
      if (this.tableName === 'tab1') this.getRequPushList();
      else this.getRequPushList2();
    },
    tabChange() {},
    //获取数据
    getRequPushList() {
      this.loading = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      if (searchForm.requ_dept_name) {
        searchForm.requ_dept_id = searchForm.requ_dept_name;
      }
      setTime(searchForm, 'startTime', 'endTime', this.dateRange || [], false);
      get(requAPI.requPush9002List, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data;
            setTimeout(() => {
              this.loading = false;
            }, 200);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getRequPushList2() {
      this.loading2 = true;
      let searchForm = cloneDeep(this.$refs.multiTable2.searchForm);
      if (searchForm.requ_dept_name) {
        searchForm.requ_dept_id = searchForm.requ_dept_name;
      }
      if (searchForm.requ_smpl_pedate) {
        searchForm.requ_smpl_pedate /= 1000;
      }
      setTime(searchForm, 'startTime', 'endTime', this.dateRange || [], false);
      getNoCatch(requAPI.requPush9002NList, searchForm).then(({ data }) => {
        this.tableData2 = data;
        setTimeout(() => {
          this.loading2 = false;
        }, 200);
      });
    },
    //双击跳转至详情页
    dbClickJp(row) {
      this.jump('/requ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 114,
            form_id: row.requ_id
          })
        )
      });
    },
    //刷新
    buttonRefresh() {
      this.dateRange = [];
      if (this.tableName === 'tab1') {
        this.$refs.multiTable.resetFields();
        this.getRequPushList();
      } else {
        this.$refs.multiTable2.resetFields();
        this.getRequPushList2();
      }
    },
    handleSelectionChange(val) {
      this.selections = val;
    },
    handleSelectionChange2(val) {
      this.selections2 = val;
    },
    //完成
    toCompleted() {
      let tab1 = this.tableName === 'tab1';
      let tab2 = this.tableName === 'tab2';
      if (tab1 && this.selections.length === 0) return this.$message.warning('请选择数据!');
      if (tab2 && this.selections2.length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('点击完成后，消息将会消失，会变为已接收', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postNoCatch(requAPI.finishRequPush, tab1 ? this.selections : this.selections2).then(({ data, msg }) => {
            this.$message.success(msg);
            if (tab1) {
              this.$refs.multiTable.clearSelection();
              this.getRequPushList();
            } else {
              this.$refs.multiTable2.clearSelection();
              this.getRequPushList2();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消完成'
          });
        });
    }
  }
};
</script>

<style></style>
